import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Circle from '../../resources/images/profile_page/grey-circle.svg';
import ProjectsList from '../core/ProjectsList';
import { Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';
import { Fade } from "react-reveal"

const ProjectsContainer = styled(Container)`
    padding-top: 45px;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background-size: 330px;
    background-position-x: 100px;
    background-position-y: 100px;
    margin: auto auto -70px auto;
    position: relative;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 160px;
        background-position-x: calc(100% - 215px);
        background-position-y: 70px;
        background-size: 580px;
        margin-bottom: -150px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-left: 156px;
        padding-right: 156px;
    }
`;

const ProjectsDescription = styled.div`
    font-size: 24px;
    line-height: 28px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;
    ${fonts.CircularMedium};
    letter-spacing: -1px;
    color: ${theme.colors.black};
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -1px;
        padding-left: 78px;
        max-width: 735px;
        padding-bottom: 140px;
    }
`;

const CircleImage = styled.img`
  height: 330px;
  width: 330px;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 580px;
    width: 580px;
  }
`;

const CircleContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 100px;
  right: 0;
  left: 0;
  margin: auto;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 580px;
    width: 580px;
    left: unset;
    right: 250px;
  }
`;

const Projects = (props) => {
    const { projects, intro } = props;
    return (
        <ProjectsContainer>
            <CircleContainer>
                <Fade bottom duration={1300}>
                    <CircleImage src={Circle} alt={"Circle"}/>
                </Fade>
            </CircleContainer>
            <Fade duration={250} delay={100}>
                <ProjectsDescription>{intro}</ProjectsDescription>
            </Fade>
            <ProjectsList projects={projects} />
        </ProjectsContainer>

    )
};

export default Projects;
