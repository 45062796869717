import React, {Component} from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import fonts from '../../styles/fonts';
import Img from 'gatsby-image';
import {Link} from 'gatsby';
import theme from '../../styles/theme';
import SocialLinks from "../core/SocialLinks";
import Slider from "react-slick/lib";
import {Fade} from "react-reveal";

const AboutContainer = styled.div`
  width: 100%;
  margin: auto;
  padding-top: 100px;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-top: 250px;
  }
`;

const RowStyled = styled(Row)`
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  outline: none;
  padding-top: ${props => props.social && '35px'};
  display: flex !important;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-top: ${props => props.social && '100px'};
  }
  @media(min-width: ${theme.breakpoints.large}) {
    padding-left: 186px;
    padding-right: 186px;
  }
`;

const ColStyled = styled(Col)`
  display: ${props => props.social && 'flex'};
  flex-direction: ${props => props.social && 'row'};
  align-items: ${props => props.social && 'center'};
  justify-content: ${props => props.social && 'flex-start'};
  @media(min-width: ${theme.breakpoints.medium}) {
    display: ${props => props.social && 'flex'};
    flex-direction: ${props => props.social && 'row'};
    justify-content: ${props => props.social && 'flex-start'};
  }
`;

const OfficeImg = styled(Img)`
  width: 100%;
  box-sizing: border-box;
  height: 384px;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 550px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    height: 785px;
  }
`;
const Title = styled.div`
  ${fonts.CircularMedium};
  font-size: 35px;
  line-height: 42px;
  padding-bottom: 20px;
  padding-top: 10px;
  color: ${theme.colors.black};
  padding-right: 40px;
  @media(min-width: ${theme.breakpoints.large}) {
    font-size: 62px;
    line-height: 74.4px;
    letter-spacing: -2px;
    max-width: 480px;
    padding-bottom: 45px;
    padding-top: 30px;
  }
`;

const AboutText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding-left: 0;
  padding-top: 40px;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-left: 40px;
    padding-top: 0;
    justify-content: center;
  }
  @media(min-width: ${theme.breakpoints.xl}) {
    padding-left: 135px;
    padding-top: 212px;
    justify-content: flex-start;
  }
`;

const StyledLink = styled(Link)`
  ${fonts.MaisonNeueDemi};
  color: ${theme.colors.black};
  text-decoration: none;
  border-bottom: 2px solid ${theme.colors.black};
  font-size: 16px;
  line-height: 19.4px;
  width: auto;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
    line-height: 21.6px;
    width: 85px;
  }
`;

const SmallTitle = styled.div`
  ${fonts.MaisonNeueLight};
  color: ${theme.colors.black};
  font-size: 16px;
  line-height: 19.2px;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
    line-height: 21.6px;
  }
`;
const Pictures = styled.div`
  width: 100%;
  padding-top: 80px;
  @media(min-width: ${theme.breakpoints.medium}) {
    max-height: 400px;
    padding-top: 180px;
  }
`;
const Picture = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  float: left;
`;
const InstaHandle = styled.div`
  ${fonts.CircularMedium};
  font-size: 30px;
  line-height: 36.5px;
  color: ${theme.colors.black};
  letter-spacing: -1px;
  width: 100%;
  @media(min-width: ${theme.breakpoints.large}){
    font-size: 46px;
    line-height: 61.62px;
    letter-spacing: -2px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  padding-top: 30px;
  padding-bottom: 35px;
  margin-left: -13px;
  z-index: 0;
  box-sizing: border-box;
  @media(min-width: ${theme.breakpoints.medium}){
    margin-left: 0;
    padding-right: 80px;
    justify-content: flex-end;
  }
`;

const Line = styled.div`
  display: none;
  @media(min-width: ${theme.breakpoints.medium}) {
    display: block;
    width: 93px;
    height: 1px;
    background: ${theme.colors.black};
    margin-right: 35px;
  }
`;

const SliderStyled = styled(Slider)`
  .slick-track {
  width: auto !important;
  }
  .slick-slide {
    height: 100%;
    left: 0 !important;
    width: 100%;
    display: none;
  }
  .slick-active {
    min-height: 200px;
    display: block;
  }
`;

const CarouselDots = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  right: 40px;
  top: calc(50% - 50px);
  @media(min-width: ${theme.breakpoints.medium}){
    display: flex;
  }
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50px;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  background: ${theme.colors.white};
  :after {
    content: '';
    height: 6px;
    width: 6px;
    z-index: 50;
    background: ${props => props.active === true ? theme.colors.black : 'transparent'};
    border: 1px solid ${theme.colors.black};
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: 0.2s;
  }
  &:hover {
    :after {
      background: rgba(0,0,0,0.8);
    }
  }
`;

const CarouselContainer = styled(Container)`
  position: relative;
`;

const ImageBlock = styled.div`
  width: 50%;
  height: 100%;
  float: left;
  @media(min-width: ${theme.breakpoints.large}) {
    width: 25%;
  }
`;

const StyledButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
`;

class HomeAbout extends Component {
    state = {
        slideIndex: 0,
    };

    changeSlide(index) {
        this.slider.slickGoTo(index)
    }

    render() {
        const { instagram, articles } = this.props;
        const { slideIndex } = this.state;
        const { changeSlide } = this;

        const settings = {
            dots: false,
            arrows: false,
            autoplay: false,
            autoplaySpeed: 5000,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            pauseOnHover: false,
            swipe: true,
            swipeToSlide: true,
            draggable: true,
            beforeChange: (current, next) => this.setState({ slideIndex: next }),
        };

        return (
            <AboutContainer>
                <CarouselContainer>
                    <SliderStyled ref={slider => (this.slider = slider)} {...settings}>
                        {articles.map((article, index) => {
                            return (
                                <RowStyled key={index}>
                                    <Col md={6}>
                                        <Fade duration={1000}>
                                            <OfficeImg fluid={article.node.thumbnail.fluid}/>
                                        </Fade>
                                    </Col>
                                    <Col md={6}>
                                        <AboutText>
                                            <SmallTitle>Lately</SmallTitle>
                                            <Title>{article.node.title}</Title>
                                            <StyledLink to={`/article/${article.node.slug}`} className={"cursor-dot-black"}>read more</StyledLink>
                                        </AboutText>
                                    </Col>
                                </RowStyled>
                            )
                        })}
                    </SliderStyled>
                    <CarouselDots>
                        {articles.map((article, index) =>
                            <StyledButton key={index} >
                                <Dot active={slideIndex === index} onClick={changeSlide.bind(this, index)} className={"cursor-dot-black"}/>
                            </StyledButton>
                        )}
                    </CarouselDots>
                </CarouselContainer>
                <Container>
                    <Pictures>
                        {instagram.map((feed, index) => {
                            return (
                                <ImageBlock key={index} className="cursor-white">
                                    <Picture fluid={feed.image.fluid} alt={feed.altText.altText}/>
                                </ImageBlock>
                            )
                        })}
                    </Pictures>
                    <RowStyled social>
                        <ColStyled md={6} social>
                            <InstaHandle>@Society_Studios</InstaHandle>
                        </ColStyled>
                        <ColStyled md={6} social>
                            <SocialIcons>
                                <Line />
                                <SocialLinks black={"true"}/>
                            </SocialIcons>
                        </ColStyled>
                    </RowStyled>
                </Container>
            </AboutContainer>
        )
    }
}

export default HomeAbout
