import React, { Component } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import fonts from '../styles/fonts';
import Clients from '../components/core/Clients';
import Connect from '../components/core/Connect';
import HomeHero from '../components/home/HomeHero';
import HomeAbout from '../components/home/HomeAbout';
import Projects from '../components/home/Projects';
import get from "lodash/get";
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import theme from '../styles/theme';
import { Fade } from "react-reveal";
import IntersectionObserver from "../components/core/IntersectionObserver";

const LayoutStyled = styled(Layout)`
  margin: 0;
`;
const BlackBackground = styled.div`
  width: 100%;
  background: ${theme.colors.black};
  ${fonts.MaisonNeueBook};
  box-sizing: border-box;
`;

const WhiteBackground = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  ${fonts.MaisonNeueBook};
  box-sizing: border-box;
`;

class IndexPage extends Component {
  state = {
    theme: "dark"
  };

  changeLogo = (shouldSwitch) => {
    let theme = "dark";

    if (shouldSwitch) {
      theme = "light";
    }

    this.setState({
      theme: theme
    })
  };

  render() {
    const path = this.props.location.pathname;
    const projects = get(this, 'props.data.allContentfulProject.edges');
    const homepage = get(this, 'props.data.allContentfulHomepage.edges')[0].node;
    const reviews = get(this, 'props.data.allContentfulReviews.edges');
    const logos = get(this, 'props.data.allContentfulClientLogo.edges');
    const articles = get(this, 'props.data.allContentfulArticle.edges');
    const instagram = get(this, 'props.data.instagram.nodes').slice(0, 4);
    const homepageCarousel = get(this, 'props.data.allContentfulCarouselSlide.edges');
    const {changeLogo} = this;

    return (
      <LayoutStyled colorScheme={this.state.theme}>
        <SEO title="Society Studios"
             description="Society Studios is a progressive, award-winning independent creative communications agency specialising in property and hospitality marketing."
             fullTitle={true}
             path={path} />
        <WhiteBackground hero>
          <Fade duration={2000} delay={100}>
            <HomeHero data={homepage} homepageCarousel={homepageCarousel} id="hero" />
          </Fade>
          <Projects intro={documentToReactComponents(homepage.intro.json)} projects={projects} />
        </WhiteBackground>
        <IntersectionObserver onChange={changeLogo}>
          <BlackBackground className={'cursor-white'}>
            <Clients reviews={reviews} logos={logos} type={'dark'} text={'Don\'t just take our word for it'} />
            <Connect content={homepage.contactBlock.content}
                     type={'dark'}
                     img={homepage.contactBlock.image.fluid} />
          </BlackBackground>
        </IntersectionObserver>
        <WhiteBackground>
          <HomeAbout articles={articles} instagram={instagram} />
        </WhiteBackground>
      </LayoutStyled>
    )
  }
};

export default IndexPage;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulProject(sort: { fields: position, order: ASC }, limit: 5, filter: {featured: {eq: true}}) {
      edges {
        node {
          clientName
          title
          slug
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1000
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulReviews(sort: { fields: position, order: ASC }, limit: 4) {
      edges {
        node {
          id
          content {
            json
          }
          clientName
          companyPosition
        }
      }
    }
    allContentfulClientLogo(sort: { fields: position, order: ASC }, limit: 8) {
      edges {
        node {
          id
          image: image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulHomepage(limit: 1) {
      edges {
        node {
          heroTitle
          heroDescription
          heroImage: heroImage {
            fluid(
              maxWidth: 1920
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          heroLink
          intro {
            json
          }
          contactBlock {
            content {
              json
            }
            image: image {
              fluid(
                maxWidth: 1000
                quality: 60
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
    allContentfulCarouselSlide {
      edges {
        node {
          videoUrl
          subtitle
          title
          ctaUrl
          image {
            fluid(
              maxWidth: 1920
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulArticle(limit: 4) {
      edges {
        node {
          id
          title
          slug
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1000
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    # allInstaNode(limit: 4) {
    #   edges {
    #     node {
    #       id
    #       localFile {
    #         childImageSharp {
    #           fluid(
    #             maxWidth: 500
    #             maxHeight: 500
    #             quality: 60
    #           ) {
    #             ...GatsbyImageSharpFluid_withWebp
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
    instagram: allContentfulInstagram {
      nodes {
        altText {
          altText
        }
        image {
          fluid(maxWidth: 500, maxHeight: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
