import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Overlay from '../../resources/images/home_page/hero-overlay.png';
import PlusLink from '../../resources/images/home_page/plus.svg';
import { Link } from 'gatsby';
import theme from '../../styles/theme';
import Slider from "react-slick";
import Img from 'gatsby-image';
import {Fade} from "react-reveal";

const HeroContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: ${theme.colors.black};
`;

const Title = styled(Link)`
    ${fonts.CircularMedium};
    font-size: 50px;
    line-height: 50px;
    letter-spacing: -2px;
    color: ${theme.colors.white};
    position: relative;
    z-index: 11;
    padding-bottom: 30px;
    text-decoration: none;
    max-width: 250px;
    &:hover {
      color: ${theme.colors.grey};
    }
    @media(min-width: ${theme.breakpoints.medium}){
      max-width: none;
    }
    @media(min-width: ${theme.breakpoints.large}) {
      font-size: 90px;
      line-height: 100.96px;
      letter-spacing: -5px;
      padding-bottom: 10px;
    }
`;

const Intro = styled.div`
    ${fonts.MaisonNeueDemi};
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: 0;
    color: ${theme.colors.white};
    position: relative;
    z-index: 11;
    @media(min-width: ${theme.breakpoints.large}) {
        font-size: 18px;
        line-height: 21.6px;
    }
`;

const HeroOverlay = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 295px;
    z-index: 1;
    @media(min-width: ${theme.breakpoints.medium}) {
        height: 517px;
    }
`;

const LinkStyled = styled(Link)`
    display: none;
    @media(min-width: ${theme.breakpoints.medium}) {
        display: block;
        height: 46px;
        width: 46px;
        z-index: 11;
        background: url(${PlusLink}) no-repeat;
        background-size: 100%;
        transition: .4s;
    }
    &:hover {
      transform: rotate(360deg);
    }
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
  position: relative;
  box-sizing: border-box;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  @media(min-width: ${theme.breakpoints.large}) {
    margin-left: auto;
    margin-right: 0;
  }
`;

const ImgStyled = styled(Img)`
  height: 100vh;
  width: 100%;
`;

const ContentBlock = styled.div`
  display: block;
  position: absolute;
  padding: 40px;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  box-sizing: border-box;
  @media(min-width: ${theme.breakpoints.large}) {
    padding: 70px 70px 80px 70px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  z-index: 2;
  .react-reveal {
    z-index: 2;
  }
`;

const VideoBlock = styled.div`
  height: 100vh;
  overflow: hidden;
  padding: 0;
  position: relative;
  iframe {
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100vh;
    min-width: 100vw;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 250vh;
  }
`;

const TransparentOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;


const HomeHero = ({homepageCarousel}) => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        swipe: true,
        swipeToSlide: true,
        draggable: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    };

    const handleMousemove = (e) => {
      let cursor = document.getElementById("custom-cursor");
      let hero = document.getElementById("hero")
      if (hero) {
        let heroHeight = hero.offsetHeight;
        let cursorPosition = e.pageY
        if (cursorPosition < heroHeight) {
          cursor.classList.add("hero")
        }
        if (cursorPosition > heroHeight) {
          cursor.classList.remove("hero")
        }
      }
    }

    useEffect(() => {
      if (typeof window !== "undefined") {
        window.addEventListener("mousemove", e => handleMousemove(e))
      }
      return () => {
        if (typeof window !== "undefined") {
          window.removeEventListener("mousemove", e => handleMousemove(e))
        }
      }
    });

    return (
        <HeroContainer className="cursor-white" id="hero">
            <Slider {...settings}>
                {homepageCarousel.map((slide, index) => {
                    return (
                        <Slide key={index}>
                            {slide.node.videoUrl !== null ?
                                <div>
                                    <VideoBlock>
                                        <TransparentOverlay/>
                                        <iframe src={`${slide.node.videoUrl}?autoplay=1&muted=1&background=1`} allow="fullscreen; autoplay" allowFullScreen={true} title="Project Video"/>
                                    </VideoBlock>
                                </div>
                                :
                                <ImgStyled fluid={slide.node.image.fluid} alt={slide.node.title}/>
                            }
                            <ContentBlock>
                                <HeroOverlay src={Overlay}/>
                                <Content>
                                    <Fade duration={1400} delay={100} bottom>
                                        <div>
                                            <Title to={slide.node.ctaUrl} className={"cursor-dot-white"}>{slide.node.title}</Title>
                                            <Intro className={"cursor-dot-white"}>
                                                {slide.node.subtitle}
                                            </Intro>
                                        </div>
                                    </Fade>
                                    <LinkStyled to={slide.node.ctaUrl} className="cursor-dot-black"/>
                                </Content>
                            </ContentBlock>
                        </Slide>
                    )
                })}
            </Slider>
        </HeroContainer>
    )
};

export default HomeHero;
